<template>

                <header
                        id="header_slider"
                        class="header_slider carousel slide carousel-fade"
                        data-ride="carousel"
                >
                    <!--<pre>{{home.sliders}}</pre>-->
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                    <div class="slider">
                        <b-carousel
                                id="carousel-fade"
                                style="text-shadow: 0px 0px 2px #000"
                                controls
                                fade
                                no-animation
                                indicators
                                img-width="1024"
                                img-height="480"
                                nav="true"  >
<!--                            <b-carousel-slide v-for="slider in home.sliders.filter(sl => sl.status === 1 )" :key="slider.id" v-on:click="gotoAnyWhere"-->
<!--                                              :img-src="'http://savershall.com/suadmin/'+slider.photo" class="img-slider"-->
<!--                            ></b-carousel-slide>-->
                          <b-carousel-slide v-for="slider in home.sliders.filter(sl => sl.status === 1 )" :key="slider.id">
                            <template #img>
                                <img class="img-slider" v-on:click="gotoSlideUrl(slider.url)"
                                    :src="'https://savershall.com/suadmin/'+slider.photo"
                                    :alt="slider.photo_alt"
                                >
                            </template>
                          </b-carousel-slide>
                        </b-carousel>

                    </div>
                            </div>
                        </div>
                    </div>
                </header>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    computed: {
      ...mapGetters(["home"]),
    },
    methods: {
      gotoSlideUrl (url) {
        if(url){
          window.location.href = url;
        }
      }
    }

  };
</script>

<style>
   /* .slider {
        padding-left: 30px;
        padding-right: 30px;
    }*/
   .img-slider{
     cursor: pointer;
   }
   .header_slider .carousel-item img {
     object-fit: cover;
     max-height: 400px;
     height: 100%;
     width: 100%;
     min-height: 105px;
   }
   .carousel-control-next,
   .carousel-control-prev {
       height: 55px !important;
       width: 35px !important;
       position: absolute;
       top: 50%;
       padding: 0px 5px;
       margin-top: -27px;
   }

   .carousel-control-prev .sr-only{
       display: none;
   }
   .carousel-control-next .sr-only{
       display: none;
   }
   .carousel-control-prev-icon {
       background-image: url("../../../assets/frontend/images/icon/01.png") !important;
       height: 35px
   }

   .carousel-control-next-icon {
       background-image: url("../../../assets/frontend/images/icon/02.png") !important;
       height: 35px
   }
</style>
