<template>
  <section  class="brand_section">
    <div class="container">
      <div class="section_title">
        <h3>BRAND'S</h3>
      </div>
      <div  class="">
      <carousel v-if="!loading"
                :autoplay="false"
                :refreshed = "true"
                :items=6
                :navText="['Prev', 'Next']"
                :loop="false"
                :margin="20"
                :dots="false"
                :responsive="{
          1200: { items: 7 },
          991: { items: 5 },
          767: { items: 4 },
          576: { items: 3 },
          0: { items: 2 },
        }"
      >
        <!-- {{home.brands}} -->
        <div class="blog_card br-img" v-for="(br, index) in brandFilterForHome" :key="index">
          <router-link v-if="br" :to="{ name: 'brand', params: { slug: br.slug}}" >
            <img style="height: 100px" :src="'https://savershall.com/suadmin/'+br.logo"  alt=""
            /></router-link>
        </div>
      </carousel>
        <div v-else class="fake-height" style="height: 108px; text-align: center"></div>


      <!--<ul>
        <li v-for="brand in brandFilterForHome" :key="brand.id">{{brand.name}}</li>
      </ul>-->
      <div class="alphabeticSearch">
        <ul>
          <li><a @click="mouseClick('A')" >A</a></li>
          <li><a @click="mouseClick('B')">B</a></li>
          <li><a @click="mouseClick('C')">C</a></li>
          <li><a @click="mouseClick('D')">D</a></li>
          <li><a @click="mouseClick('E')">E</a></li>
          <li><a @click="mouseClick('F')">F</a></li>
          <li><a @click="mouseClick('G')">G</a></li>
          <li><a @click="mouseClick('H')">H</a></li>
          <li><a @click="mouseClick('I')">I</a></li>
          <li><a @click="mouseClick('J')">J</a></li>
          <li><a @click="mouseClick('K')">K</a></li>
          <li><a @click="mouseClick('L')">L</a></li>
          <li><a @click="mouseClick('M')">M</a></li>
          <li><a @click="mouseClick('N')">N</a></li>
          <li><a @click="mouseClick('O')">O</a></li>
          <li><a @click="mouseClick('P')">P</a></li>
          <li><a @click="mouseClick('Q')">Q</a></li>
          <li><a @click="mouseClick('R')">R</a></li>
          <li><a @click="mouseClick('S')">S</a></li>
          <li><a @click="mouseClick('T')">T</a></li>
          <li><a @click="mouseClick('U')">U</a></li>
          <li><a @click="mouseClick('V')">V</a></li>
          <li><a @click="mouseClick('W')">W</a></li>
          <li><a @click="mouseClick('X')">X</a></li>
          <li><a @click="mouseClick('Y')">Y</a></li>
          <li><a @click="mouseClick('Z')">Z</a></li>
          <li><a @click="mouseClick('#')">#</a></li>
        </ul>

      </div>
      </div>
      <!--<pre>{{brandVal}}</pre>-->
    </div>
  </section>
</template>

<script>
  import { mapGetters } from "vuex";
  import carousel from "vue-owl-carousel";
  export default {
    data(){
      return{
        render:true,
        brandVal : '',
        page:1,
        brandSlide: false
      }
    },
    components: { carousel },
    computed: {
      ...mapGetters(["brandFilterForHome","loading"]),
    },
    watch: {
      brandFilterForHome(value){
        this.brandVal = value;
      }

    },
    mounted() {
      setTimeout(function () {
        this.brandSlide = true
      }.bind(this), 1000)
      this.$store.dispatch('getBrandByTextFilteringHome', '#');
      this.brandVal = this.brandFilterForHome;
      //this.$store.dispatch('getBrands');
      /*this.$store.dispatch('getBrandByTextFiltering', "#");
      console.log("getBrandByTextFiltering =", this.getBrandByTextFiltering);*/
    },
    methods:{
      mouseClick(val) {
        console.log("time")
        this.brandVal = '';
        this.render = false;
        this.$store.dispatch('getBrandByTextFilteringHome', val);
        /*setTimeout(() => {


          console.log("time ====>>>>>>>>>>>>>>>>>>>>>>>>>>>")
        }, 0);*/

      }
    }
  };
</script>
<style lang="scss">
  .alphabeticSearch {
    text-align: center;
    li {
      display: inline-block;
      margin-right: 3px;
    }
    a {
      display: block;
      padding: 0px 9px;
      background: #fff;
      font-weight: 700;
      color: #000;
      border-radius: 2px;
      &:hover{
        background: #000;
        color:#fff !important;
      }
    }

  }

  .br-img img {filter: grayscale(1);}

  .br-img img:hover {
    filter: unset;
  }


</style>
