<!--<template>
  <section class="product_section currently_treanding">
    <div class="container">
      <div class="section_title">
        <h3>{{ home.homeViewCategoryProducts[0].name }}</h3>

        &lt;!&ndash; {{home.productsHomeViewCategory}} &ndash;&gt;
      </div>
      <SingleProductForHome :products="home.newProducts" />
    </div>
  </section>
</template>-->
<template>
  <section  class="product_section top_selling">
    <div class="container" v-for="product in home.homeViewCategoryProducts" :key="product.id">
      <div class="section_title">
        <!--<h3>{{ home.homeViewCategoryProducts[0].name }}</h3>-->
        <h3>{{ product.name }}</h3>
      </div>
      <!-- {{topSelling}} -->
      <SingleProductForHome :products="product.products" />

      <!--<div class="text-center">
        <a class="load_more" href="">Load More</a>
      </div>-->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
//import SingleProduct from "@/components/frontend/singleProduct/SingleProduct.vue";
import SingleProductForHome from "@/components/frontend/singleProduct/SingleProductForHome.vue";
export default {
  components: {
    SingleProductForHome
  },
  computed: {
    ...mapGetters(["home", "accessToken", "customer"]),
  },
  // methods: {
  //   addToWishlist(productId, customerId) {
  //     // alert('ok');
  //     if (this.accessToken) {
  //       const product = {
  //         product_id: productId,
  //         customer_id: customerId,
  //       };
  //       this.$store.dispatch("addToWishlist", product);
  //     } else {
  //       this.$router.push("customer");
  //     }
  //     console.log(productId, customerId);
  //   },
  // },
};
</script>

<style></style>
