<template>
  <section class="product_section new_arrival">
    <div class="container">
      <div class="section_title">
        <h3>New Arrival</h3>
      </div>
      <NewArrival :products="home.newProducts" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
//import SingleProduct from "@/components/frontend/singleProduct/SingleProduct.vue";
import NewArrival from "@/components/frontend/singleProduct/SingleProductForHome.vue";
export default {
  components: {
    NewArrival
  },
  computed: {
    ...mapGetters(["home", "accessToken", "customer"]),
  },
};
</script>
<style></style>
