<template>
  <section class="offerProduct_section">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div class="product_area">
            <!--<pre>{{topProduct(4)}}</pre>-->
            <figure class="product_img">
              <a :href="topProduct(3).url">
              <img
                :src="'https://savershall.com/suadmin/'+topProduct(3).image
                "
                alt=""
              />
              </a>
            </figure>
            <div class="product_article">
              <h4 v-if="topProduct(3).name">{{ topProduct(3).name | truncate(78, '...')}}:</h4>
              <p v-if="topProduct(3).short_description">
                {{ topProduct(3).short_description | truncate(78, '...')}}
              </p>
              <a :href="topProduct(3).url" class="btn shop_btn">Shop Now</a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6">
          <div class="product_area">
            <figure class="product_img">
              <a :href="topProduct(4).url">
              <img
                :src="'https://savershall.com/suadmin/'+topProduct(4).image
                "
                alt=""
              />
              </a>
            </figure>
            <div class="product_article">
              <h4 v-if="topProduct(4).name">{{ topProduct(4).name | truncate(78, '...')}}:</h4>
              <p v-if="topProduct(4).short_description">
                {{ topProduct(4).short_description | truncate(78, '...')}}
              </p>
              <a :href="topProduct(4).url" class="btn shop_btn">Shop Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="product_area">
            <figure class="product_img">
              <a :href="topProduct(5).url">
              <img
                :src="'https://savershall.com/suadmin/'+topProduct(5).image"
                alt=""
              />
              </a>
            </figure>
            <div class="product_article">
              <h4 v-if="topProduct(5).name">{{ topProduct(5).name | truncate(78, '...')}}:</h4>
              <p v-if="topProduct(5).short_description">
                {{ topProduct(5).short_description | truncate(78, '...')}}
              </p>
              <a :href="topProduct(5).url" class="btn shop_btn">Shop Now</a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6">
          <div class="product_area">
            <figure class="product_img">
              <a :href="topProduct(6).url">
              <img
                :src="'https://savershall.com/suadmin/'+topProduct(6).image
                "
                alt=""
              />
              </a>
            </figure>
            <div class="product_article">
              <h4 v-if="topProduct(6).name">{{ topProduct(6).name | truncate(78, '...')}}:</h4>
              <p v-if="topProduct(6).short_description">
                {{ topProduct(6).short_description | truncate(78, '...')}}
              </p>
              <a :href="topProduct(6).url" class="btn shop_btn">Shop Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="product_area">
            <figure class="product_img">
              <a :href="topProduct(7).url">
              <img
                :src="
                  'https://savershall.com/suadmin/'+topProduct(7).image
                "
                alt=""
              />
              </a>
            </figure>
            <div class="product_article">
              <h4 v-if="topProduct(7).name">{{ topProduct(7).name | truncate(78, '...')}}:</h4>
              <p v-if="topProduct(7).short_description">
                {{ topProduct(7).short_description | truncate(78, '...')}}
              </p>
              <a :href="topProduct(7).url" class="btn shop_btn">Shop Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="product_area">
            <figure class="product_img">
              <a :href="topProduct(8).url">
              <img
                :src="
                  'https://savershall.com/suadmin/'+topProduct(8).image
                "
                alt=""
              />
              </a>
            </figure>
            <div class="product_article">
              <h4 v-if="topProduct(8).name">{{ topProduct(8).name | truncate(78, '...')}}:</h4>
              <p v-if="topProduct(8).short_description">
                {{ topProduct(8).short_description | truncate(78, '...')}}
              </p>
              <a :href="topProduct(8).url" class="btn shop_btn">Shop Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["topProduct"]),
  },
};
</script>

<style scoped>
  .product_article h4 {
    height: 58px;
  }
  .product_article p {
    height: 50px;
  }
</style>
