<template>
  <section class="update_section">
    <div class="container">
      <div class="section_title">
        <h3>
          LIFE IS ABOUT CREATING AND LIVING EXPERIENCES THAT ARE WORTH SHARING
        </h3>
        <p>
          Show us your latest purchase and tag #savershall on Instagram,
          facebook or simply upload your image directly for a chance to feature.
          Best feature will be selected to win a prize
        </p>
      </div>
      <div class="title_btn">
        <div class="nav btn_group">
          <button type="button" class="nav-link active social-upload-btn" @click="showModal = true">Upload Image</button>
          <button type="button" class="nav-link"  @click="gotoevent">See more</button>

        </div>
      </div>
      <div class="tab-content">
        <div v-if="home.socialEvents.length > 0" class="tab-pane fade show active" id="upload_image">
          <!-- <div class="owl-carousel update_carousel"> -->
          <carousel
            :items="4"
            :lazyLoad=true
            :dots="false"
            :autoplay="true"
            :margin="15"
            :autoplayTimeout="5000"
            :loop="true"
            :responsive="{
              991: { items: 4 },
              767: { items: 3 },
              576: { items: 2 },
              0: { items: 1 },
            }"
            :nav="true"
          >
            <div
              class="product_card"
              v-for="socialEvent in home.socialEvents"
              :key="socialEvent.id"
            >
              <img
                v-if="socialEvent.image[0]"
                :src="
                  'https://savershall.com/suadmin/'+socialEvent.image
                "
                alt=""
              />
              <a :href="socialEvent.url" class="product_cover">
                <div class="content">
                  <i class="icon ion-md-add"></i>
                  <span>Shop Now</span>
                </div>
              </a>
            </div>
          </carousel>
        </div>
      </div>

<!--      <div class="tab-pane fade" id="see_more">
        <div class="owl-carousel see_more">
          <div class="product_card">
            <img src="@/assets/frontend/images/update/01.png" alt="" />
            <a href="" class="product_cover">
              <div class="content">
                <i class="icon ion-md-add"></i>
                <span>Shop Now</span>
              </div>
            </a>
          </div>
          <div class="product_card">
            <img src="@/assets/frontend/images/update/02.png" alt="" />
            <a href="" class="product_cover">
              <div class="content">
                <i class="icon ion-md-add"></i>
                <span>Shop Now</span>
              </div>
            </a>
          </div>
          <div class="product_card">
            <img src="@/assets/frontend/images/update/03.png" alt="" />
            <a href="" class="product_cover">
              <div class="content">
                <i class="icon ion-md-add"></i>
                <span>Shop Now</span>
              </div>
            </a>
          </div>
          <div class="product_card">
            <img src="@/assets/frontend/images/update/04.png" alt="" />
            <a href="" class="product_cover">
              <div class="content">
                <i class="icon ion-md-add"></i>
                <span>Shop Now</span>
              </div>
            </a>
          </div>
          <div class="product_card">
            <img src="@/assets/frontend/images/update/05.png" alt="" />
            <a href="" class="product_cover">
              <div class="content">
                <i class="icon ion-md-add"></i>
                <span>Shop Now</span>
              </div>
            </a>
          </div>
          <div class="product_card">
            <img src="@/assets/frontend/images/update/03.png" alt="" />
            <a href="" class="product_cover">
              <div class="content">
                <i class="icon ion-md-add"></i>
                <span>Shop Now</span>
              </div>
            </a>
          </div>
        </div>
      </div>-->
    </div>
    <!-- </div> -->
<!--    Modal   -->
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <form @submit.prevent="uploadImage" enctype="multipart/form-data">
                  <div class="modal-header">
                    <h5 class="modal-title">Review</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" @click="showModal = false">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div v-if="accessToken" class="form-group">
                      <input type="file" ref="image" accept="image/*">
                      <br>
                      <img v-if="preview_img" class="customer_prev_img" :src="preview_img"/>
                    </div>
                    <div v-else class="">Please login
                      <router-link  :to="{ name: 'login' }">
                        here..
                      </router-link></div>
                  </div>
                  <div v-if="accessToken" class="modal-footer">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import carousel from "vue-owl-carousel";
import axios from "../../../axios";
//import Cookies from 'js-cookie';
// function getCookie(xsrftoken) {
//   const cookieValue = document.cookie.match('(^|;)\\s*' + xsrftoken + '\\s*=\\s*([^;]+)');
//   return cookieValue ? cookieValue.pop() : '';
// }


export default {
  data(){
    return{
      showModal: false,
      brandSlide: false,
      preview_img:'',
      image:'',
      //csrf: document.querySelector('meta[name="csrf-token"]').content,
      customer_review : {
        name:'',
        image:'',
        url:''
      }
    }
  },
  components: { carousel },
  computed: {
    ...mapGetters(["home", "me", "accessToken"]),
  },
  watch:{
    image(){
      console.log("--------")
    }
  },
  mounted() {
    setTimeout(function () {
      this.brandSlide = true
    }.bind(this), 2000)
  },
  methods:{
    uploadImage() {
      const imageInput = this.$refs.image;

      if (imageInput && imageInput.files.length > 0) {
        const formData = new FormData();
        formData.append('image', imageInput.files[0]);
        formData.append('name', this.me.customer_name);
        formData.append('id', this.me.id);
        if(imageInput.files[0].size > 0){
          this.preview_img = URL.createObjectURL(imageInput.files[0]);
        }
        axios.post('https://savershall.com/suadmin/api/socialevents/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
            .then(response => {
              if(response.data.message){
                this.showModal = false;
                this.$swal({
                  text: "Thank you for submit your review.",
                  confirmButtonColor: '#65c03a'
                })
              }

            })
            .catch(error => {
              console.error(error.response.data.message);
            });
      } else {
        console.warn('No image selected.');
      }
    },
    gotoevent(){
      this.$router.push("pages/all-social-events");
    }
    // uploadImage(e){
    //   console.log(e.target.files[0]);
    //   this.image = e.target.files[0];
    // },
    // uploadImage(event){
    //   if(event.target.files[0] === undefined){
    //     this.preview_img = '';
    //   }
    //   console.log("Upload",event.target)
    //   this.customer_review.image = event.target.files[0];
    //   if(event.target.files[0].size > 0){
    //     this.preview_img = URL.createObjectURL(this.customer_review.image);
    //   }
    //   // axios.get(`https://savershall.com/sh_backapi/api/csrf-cookie`).then(resc => {
    //   //   console.log("review done", resc)
    //   // })
    // },
    // formSubmit(e) {
    //   e.preventDefault();
    //   let currentObj = this;
    //
    //   const config = {
    //     headers: { 'content-type': 'multipart/form-data' }
    //   }
    //
    //   let formData = new FormData();
    //   formData.append('image', this.image);
    //   formData.append('name', this.me.customer_name);
    //   formData.append('id', this.me.id);
    //
    //   console.log("ddddd==", formData);
    //   axios.post('https://savershall.com/suadmin/api/socialevents/create', formData, config)
    //       .then(function (response) {
    //         currentObj.success = response.data.success;
    //         console.log("res", currentObj.success)
    //       })
    //       .catch(function (error) {
    //         currentObj.output = error;
    //         console.log("err", currentObj.output)
    //       });
    // },
    // submitReview(){
    //   this.customer_review.id = this.me.id,
    //   this.customer_review.name = this.me.customer_name,
    //   //this.customer_review._token = this.csrf,
    //   console.log("Submit review =",this.customer_review);
    //   //this.customer_review._token = this.accessToken;
    //   axios.post(`https://savershall.com/suadmin/api/socialevents/create`, this.customer_review, {
    //     headers:{
    //       'Authorization': `Bearer ${this.accessToken}`
    //     }
    //   }).then(res => {
    //     console.log("review done", res)
    //   })
    //
    // }
  }
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.customer_prev_img{
  padding: 10px;
  border: 1px solid #ddd;
  margin-top: 5px;
  border-radius: 3px;
  width: 25%;
}
button.nav-link.active.social-upload-btn {
  border: none;
}
</style>
