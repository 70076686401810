<template>
  <section class="blog_section">
    <div class="container">
      <div class="section_title">
        <h3>Blog: <span>We care about you</span></h3>
      </div>
<div v-if="home.blogs.length > 0" class="">
      <carousel
        :items="4"
        :dots="false"
        :autoplay="true"
        :margin="10"
        :autoplayTimeout="5000"
        :loop="false"
        :responsive="{
          991: { items: 4 },
          767: { items: 3 },
          576: { items: 2 },
          0: { items: 1 },
        }"
        :nav="true"
      >
        <div class="blog_card" v-for="blog in home.blogs" :key="blog.id">
            <img style="cursor: pointer"
                @click="goToBlog(blog.url)"
                v-if="blog.image"
                :src="'https://savershall.com/suadmin/'+blog.image"
                alt=""
            />
            <a target="_blank" :href="blog.url">{{ blog.title }}</a>
        </div>
      </carousel>
</div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import carousel from "vue-owl-carousel";
export default {
  data(){
    return{
      brandSlide: false
    }
  },
  components: { carousel },
  computed: {
    ...mapGetters(["home"]),
  },
  mounted() {
    setTimeout(function () {
      this.brandSlide = true
    }.bind(this), 2000)
  },
  methods:{
    goToBlog(url){
      window.open(url, '_blank');
    }
  }
};
</script>

<style></style>
