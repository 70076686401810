<template>
  <div>
    <!--<div v-if="loading">Loading...</div>-->

      <Homepage />

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Homepage from "../component/home/Homepage";
export default {
  name: "Home",
  metaInfo: {
    title: 'Savers Hall',
    titleTemplate: '%s | Savers Hall'
  },
  components: {
    Homepage,
  },
  async mounted() {
    await this.$store.dispatch("getHome");
    await this.$store.dispatch("getsaleNow");
    await this.$store.dispatch("getTopSelling");
    // await this.$store.dispatch("getCustomerWishlist");
  },
  data() {
    return {
      msg: "",
    };
  },
  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>
