<template>
  <section class="product_section top_selling">
    <div class="container">
      <div class="section_title">
        <h3>Top Selling</h3>
      </div>
<!--      <pre>{{allProduct.length}}/{{totalProduct}}</pre>-->
      <TopSelling :products="allProduct" />
        <div class="text-center">
          <button v-if="totalProduct < allProduct.length?false:true"  @click="loadMoreProduct" type="button" class="btn btn-dark" style=" padding: 5px 15px">LOAD MORE</button>
        </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
//import SingleProduct from "@/components/frontend/singleProduct/SingleProduct.vue";
import TopSelling from "@/components/frontend/singleProduct/SingleProductForHome.vue";
import axios from "axios";
export default {
  data() {
    return {
      allProduct : [],
      totalProduct:0
    };
  },
  components: {
    TopSelling
  },
  computed: {
    ...mapGetters(["topSelling", "accessToken", "customer","loadMorePage"]),
  },
  mounted() {
    this.$store.dispatch('loadMorePage', 1);
    axios.get(`https://savershall.com/sh_backapi/api/product-top-selling?page=1`).then(reponse =>{
      console.log("=======++++",reponse.data)
      this.totalProduct = reponse.data.meta.total
      this.allProduct = reponse.data.data;
    })
  },
  methods:{
    loadMoreProduct(){
      let page = parseInt(this.loadMorePage) + 1;
      this.$store.dispatch('loadMorePage', page);
      console.log("Page = ",page);
        axios.get(`https://savershall.com/sh_backapi/api/product-top-selling?page=${page}`)
            .then(response => (
                response.data.data.forEach(item => {
                  this.allProduct.push(item);
                })
            ));
    },
  }
  // methods: {
  //   addToWishlist(productId, customerId) {
  //     // alert('ok');
  //     if (this.accessToken) {
  //       const product = {
  //         product_id: productId,
  //         customer_id: customerId,
  //       };
  //       this.$store.dispatch("addToWishlist", product);
  //     } else {
  //       this.$router.push("customer");
  //     }
  //     console.log(productId, customerId);
  //   },
  // },
};
</script>

<style></style>
