<template>
  <section class="youtube_section">
<!--    <pre>{{home.eurtubes}}</pre>-->
    <div v-if="home.eurtubes.length > 0" class="container">
      <div class="section_title">
        <h3>Savers Tube</h3>
        <!-- {{home.eurtubes}} -->
      </div>
      <carousel
        :autoplayTimeout="5000"
        :autoplay="true"
        :loop="true"
        :nav="true"
        :dots="false"
        :margin="5"
        :responsive="{
          991: { items: 4 },
          767: { items: 3 },
          576: { items: 2 },
          0: { items: 1 },
        }"
      >
        <div
          class="video_box"
          v-for="youtube in home.eurtubes"
          :key="youtube.id"
        >
          <iframe
            v-if="youtube.embeded_code[0]"
            width="322"
            height="185"
            :src="youtube.embeded_code"
            title=""
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </carousel>
    </div>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";
import { mapGetters } from "vuex";
export default {
  components: { carousel },
  computed: {
    ...mapGetters(["home"]),
  },
};
</script>

<style></style>
