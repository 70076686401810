<template>
  <section class="offer_section">
    <div class="container">
      <div class="row">
        <div
          class="col-md-3 col-6"
          v-for="specialCategory in home.specialCategories"
          :key="specialCategory.id"
        >
          <router-link
          :to="{
            name: 'category',
            params: {
              slug: specialCategory.slug,
            },
          }"
          class="offer_img"
          >
          
            <img
              v-if="specialCategory.special_category_image[0]"
              :src="'https://savershall.com/suadmin/'+specialCategory.special_category_image"
              alt=""
            />
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["home"]),
  },
};
</script>

<style></style>
