<template>
  <section class="topProduct_section">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="product_area">
            <a :href="topProduct(1).url" >
            <img
              class="offer_tags"
              :src="
                'https://savershall.com/suadmin/'+topProduct(1).title_image
              "
              alt=""
            /></a>
            <figure class="product_img">
              <a :href="topProduct(1).url" >
              <img
                :src="
                  'https://savershall.com/suadmin/'+topProduct(1).image
                "
                alt=""
              />
              </a>
            </figure>
            <div class="product_article">
              <h4 v-if="topProduct(1).name">{{topProduct(1).name | truncate(30, '...')}}:</h4>
              <p v-if="topProduct(1).short_description">
                {{ topProduct(1).short_description }}
              </p>
              <a :href="topProduct(1).url" class="btn shop_btn">Shop Now</a>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="product_area">
            <a :href="topProduct(2).url">
            <img
              class="offer_tags"
              :src="
                'https://savershall.com/suadmin/'+topProduct(2).title_image
              "
              alt=""
            />
            </a>
            <figure class="product_img">
              <a :href="topProduct(2).url">
              <img
                :src="
                  'https://savershall.com/suadmin/'+topProduct(2).image
                "
                alt=""
              />
              </a>
            </figure>
            <div class="product_article">
              <h4 v-if="topProduct(2).name">{{ topProduct(2).name }} :</h4>
              <p v-if="topProduct(2).short_description">
                {{ topProduct(2).short_description }}
              </p>
              <a :href="topProduct(2).url" class="btn shop_btn">Shop Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["topProduct"]),
  },
};
</script>

<style scoped>
  .product_article h4 {
    height: 60px;
  }
</style>
