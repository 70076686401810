<template>
  <section class="product_section sale_now">
    <div class="container">
      <div class="section_title">
        <h3>Sale Now</h3>
        <!-- {{saleNow}} -->
        <!-- <span>
          <pre>{{ wishlist }}</pre>
        </span> -->
      </div>
      <SaleNowProduct :products="saleNow" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
//import SingleProduct from "@/components/frontend/singleProduct/SingleProduct.vue";
import SaleNowProduct from "@/components/frontend/singleProduct/SingleProductForHome.vue";
export default {
  components: {
    SaleNowProduct
  },
  computed: {
    ...mapGetters(["saleNow", "accessToken", "customer", "wishlist"]),
  },
  // methods: {
  //   addToWishlist(productId, customerId) {
  //     // alert('ok');
  //     if (this.accessToken) {
  //       const product = {
  //         product_id: productId,
  //         customer_id: customerId,
  //       };
  //       this.$store.dispatch("addToWishlist", product);
  //     } else {
  //       this.$router.push("customer");
  //     }
  //     console.log(productId, customerId);
  //   },
  // },
};
</script>

<style scoped>

</style>
