<template>
  <div>
    <SliderHeader></SliderHeader>
    <TopProduct></TopProduct>
    <Offer></Offer>
    <div class="">
      <Brand></Brand>
    </div>
    <OfferProduct></OfferProduct>
    <SalesNow></SalesNow>

<!--    <CurrentlyTrading></CurrentlyTrading>-->

    <GiftWeek></GiftWeek>
    <NewArrival></NewArrival>

    <TopSelling></TopSelling>
    <div>
      <Youtube></Youtube>
      <Blog></Blog>
      <UpdateProduct></UpdateProduct>
    </div>




  </div>
</template>

<script>
import SliderHeader from "@/components/frontend/home/SliderHeader.vue";
import TopProduct from "@/components/frontend/home/TopProduct.vue";
import Offer from "@/components/frontend/home/Offer.vue";
import Brand from "@/components/frontend/home/Brand.vue";
import OfferProduct from "@/components/frontend/home/OfferProduct.vue";
import SalesNow from "@/components/frontend/home/SalesNow.vue";
// import CurrentlyTrading from "@/components/frontend/home/CurrentlyTrading.vue";
import GiftWeek from "@/components/frontend/home/GiftWeek.vue";
import NewArrival from "@/components/frontend/home/NewArrival.vue";
import TopSelling from "@/components/frontend/home/TopSelling.vue";
import Youtube from "@/components/frontend/home/Youtube.vue";
import Blog from "@/components/frontend/home/Blog.vue";
import UpdateProduct from "@/components/frontend/home/UpdateProduct.vue";
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
window.fbq('init', '865834318321102');
window.fbq('track', 'PageView', {value: 0, currency: 'BDT'});
export default {
  name: "Homepage",
  components: {
    SliderHeader,
    TopProduct,
    Offer,
    Brand,
    OfferProduct,
    SalesNow,
    // CurrentlyTrading,
    GiftWeek,
    NewArrival,
    TopSelling,
    Youtube,
    Blog,
    UpdateProduct,
  },
  data() {
    return {
      isSliderLoad: false
    };
  },
  async mounted() {
    setTimeout(function () {
      this.isSliderLoad = true
    }.bind(this), 2000)
  },
};
</script>
<style lang="scss">
@import "@/assets/frontend/style/home.scss";
</style>
